import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//window.axios = require('axios');//Global declaration
import axios from 'axios';
import moment from 'moment'
 axios.defaults.baseURL = 'https://school.bugfixhub.com/admin/api';
 axios.defaults.headers.common["Authorization"] = 'Bearer ' + '1|4YaMHCin6hYCICv40Rc3zfASDyjhFr9NEqGrpDUK';


import { createStore } from 'vuex';
const store = createStore({
    state () {
        return {
            website_color:'',
            count:23453454
        }
    },

    getters: {
        themeColor(state){
            return state.website_color;
        },
    },

    actions: {
        websiteColor(context){
            axios.get('/theme')
                .then((response) => {
                 console.log(response.data)
                    context.commit('website_color', response.data);//students comes from controller
                });
        },

    },
    mutations: {
        //website_color() comes from actions
        website_color(state, payload){
            return state.website_color = payload;
        },
    }

});
const app = createApp(App);



app.config.globalProperties.$filters = {

    formatDateTime(value){
        if (value) {
            return moment(String(value))
            // .format('YYYY-MM-DD hh:mm A')
        }
    },

    dateTimeFormat(value){
        if (value) {
            return moment(String(value)).format('YYYY-MM-DD hh:mm A')
        }
    },

}


app.use(router).use(store).mount('#smapp')
