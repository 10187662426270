<template>
 

    <!--Middle-->
    <!--Slider-->
    <div class="row UMainSlider">
      <div class="col-md-12">
        <!--Carousel container -->
        <div id="my-pics" class="carousel slide" data-ride="carousel" style="">
          <!-- Indicators -->
          <ol class="carousel-indicators">
            <li data-target="#my-pics" data-slide-to="0" class="active"></li>
            <li data-target="#my-pics" data-slide-to="1"></li>
            <li data-target="#my-pics" data-slide-to="2"></li>
          </ol>

          <!-- Content -->
          <div class="carousel-inner" role="listbox">

            <!-- Slide 1 -->
            <div class="item active">
              <img :src="VUE_APP_API_URL+'sliders/'+sliders.slider1" alt="" style="width:100%; height:300px">
              <div class="carousel-caption">
                <h3>Boracay</h3>
                <h4>White Sand Beach.</h4>
              </div>
            </div>

            <!-- Slide 2 -->
            <div class="item">
              <img :src="VUE_APP_API_URL+'sliders/'+sliders.slider2" alt="" style="width:100%; height:300px">
              <div class="carousel-caption">
                <h3>Rob Roy Glacier</h3>
                <h4>You can almost touch it!</h4>
              </div>
            </div>

            <!-- Slide 3 -->
            <div class="item">
              <img :src="VUE_APP_API_URL+'sliders/'+sliders.slider3" alt="" style="width:100%; height:300px">
              <div class="carousel-caption">
                <h3>Phi Phi</h3>
                <h4>Longtail boats at Phi Phi.</h4>
              </div>
            </div>

          </div>

          <!-- Previous/Next controls -->
          <a class="left carousel-control" href="#my-pics" role="button" data-slide="prev">
            <span class="icon-prev" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="right carousel-control" href="#my-pics" role="button" data-slide="next">
            <span class="icon-next" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
    <!--Slider-->


    <div class="row UAboutTarabo">
      <div class="col-sm-12">
        <div :class="'border_bottom-'+$store.state.website_color">
          <span :class="'title-'+$store.state.website_color" class="UMarginTop20"><a href="#">ABOUT SCHOOL</a></span>
        </div>
        <h4 style="color:ffffff"></h4>
        <p style="padding:10px">
          {{about_school.note}}
        </p>
      </div>
    </div>

   <div class="row UService UMarginTop20">
        <div class="col-sm-12">
          <div :class="'border_bottom-'+$store.state.website_color">
            <span :class="'title-'+$store.state.website_color"><a href="#">OUR VISSION AND MISSION </a></span>
          </div>

          <div class="row">
            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingOne">
                  <h2 class="panel-title">
                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                      <b>Vission</b>
                    </a>
                  </h2>
                </div>
                <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                  <div class="panel-body">
                    {{about_school.vission}}
                  </div>
                </div>
              </div>
              <div class="panel panel-default">
                <div class="panel-heading" role="tab" id="headingTwo">
                  <h2 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                      <b>Mission</b>
                    </a>
                  </h2>
                </div>
                <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                  <div class="panel-body">
                      {{about_school.mission}}
                  </div>
                </div>
              </div>
            
            </div>
                    
          </div>
        </div>
    </div>




    <!--
    <div class="row UService UMarginTop20">
      <div class="col-sm-12">
        <div :class="'border_bottom-'+$store.state.website_color">
          <span :class="'title-'+$store.state.website_color"><a href="#">সেবাসমূহ</a></span>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <div class="UServiceList">
              <a href="#">
                <h5>Development</h5>
                <img src="assets/images/imgAll/S-Development.jpg" alt="Development"
                     title="Development" class="img-responsive img100">
              </a>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="UServiceList">
              <a href="#">
                <h5>Holding Tax</h5>
                <img src="assets/images/imgAll/S-Holding-Tax.jpg" alt="Holding Tax"
                     title="Holding Tax" class="img-responsive img100">
              </a>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="UServiceList">
              <a href="#">
                <h5>Trade License</h5>
                <img src="assets/images/imgAll/S-Trade-License.jpg" alt="Trade License"
                     title="Trade License" class="img-responsive img100">
              </a>
            </div>
          </div>
        </div>
      
      </div>
    </div>
    -->
    

    <div class="row UMarginTop15">
      <div class="col-sm-12">
        <h4 :class="'title2-'+$store.state.website_color">সচেতনতা</h4>
        <div class="UCategoryList">
          <img src="assets/images/imgAll/dengue.jpg" alt="Awareness" title="Awareness"
               class="img-responsive">
        </div>
      </div>
    </div>



  <!--Middle-->

  <!--left Aside-->
  <LeftAside v-if="settings"/>
  <!--left Aside-->


</template>

<script>

    import LeftAside from './LeftAside.vue';
    import axios from 'axios';


    export default {
        props:[
            'settings'

        ],
        name: 'App',
        components: {

            LeftAside,

        },
         data () {
            return {
              about_school: {},
              sliders: {},
              VUE_APP_API_URL:process.env.VUE_APP_API_URL
            }
          },
        mounted () {
              axios.get('slider').then(response => (
                 this.sliders = response.data
               )).catch(err => {
                console.log(err.response);
              });

              axios.get('about_school').then(response => (
                 this.about_school = response.data
               )).catch(err => {
                console.log(err.response);
              });
            
         }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
