<template>

  <div class="row UService UMarginTop20">
    <div class="col-sm-12">
      <div class="border_bottom">
        <span :class="'title-'+$store.state.website_color"><a href="#">Notice Details</a></span>
      </div>
      <div class="row" >
        <div class="col-sm-12" style="margin-bottom:15px">
          <div class="info-line UServiceList"  style="padding:7px 15px;">
            <p class="title-text" style="margin-bottom:0; "><i class="fas fa-newspaper"></i> {{ notice_details.title}} </p>
            <p style="padding:0 0 12px 25px" class="info-line"><span class="time"><i class="far fa-clock"></i> </span><span class="place"></span>{{ $filters.dateTimeFormat(notice_details.date) }}</p>


            <p class="">{{notice_details.description}}</p> <br>

          </div>
        </div>
      </div>

    </div>

  </div>



</template>

<script>
//    import LeftAside from './LeftAside.vue'
import axios from 'axios';
    export default {
        props:[
          'notice_id',
        ],

        data() {
            return {

                notice_details:{},
            }
        },

        mounted() {
            axios.get('/notice/details/'+this.notice_id)
                .then(response => {
                    this.notice_details = response.data;
                    console.log('call mounted ')
                });
//            window.scrollTo(0,0);

        },

        methods: {
            UpperCase(value) {
                return value.toUpperCase()
            },

        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
