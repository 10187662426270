<template>
  
 <div class="row UMarginTop15">
      <div class="col-sm-12">
        <img src="assets/images/imgAll/mujib.jpg" alt="Awareness" title="Awareness"
             class="img-responsive">
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <h4 :class="'title2-'+$store.state.website_color">NOTICE BOARD</h4>
      
        <div class="UCategoryList">
          <marquee direction="down" height="250" scrollamount="4" onMouseOver="this.stop()"
            onMouseOut="this.start()">

            <div class="news-item" v-for="notice in notices" :key="notice.id">
                <router-link v-bind:to="'/notice/details/' + notice.id">
                <p>{{notice.title}}</p>
                <h5>২৭-১১-২০১৯</h5>
                </router-link>
            </div>
          

          </marquee>
        </div>
      </div>
		</div>
    
    <div class="row UMarginTop15">
      <div class="col-sm-12">
        <h4 :class="'title2-'+$store.state.website_color">LINKS</h4>
        <div class="UCategoryList">
          <div class="list-group">
            <a href="http://bris.lgd.gov.bd/pub/?pg=application_form"
               title="Birth and Death Registration" target="_blank"
               class="list-group-item">Birth and Death Registration</a>
            <a href="http://www.cga.gov.bd/index.php?option=com_wrapper"
               title="Online Invoice Verification" target="_blank"
               class="list-group-item">Online Invoice Verification</a>
            <a href="http://www.bmet.gov.bd/BMET/onlinaVisaCheckAction" title="Verify Visa"
               target="_blank" class="list-group-item">Verify Visa</a>
            <a href="http://www.nbrepayment.gov.bd/" title="e-Tax Payment" target="_blank"
               class="list-group-item">e-Tax Payment</a>
            <a href="https://services.nidw.gov.bd/"
               title="Updating national identity card information" target="_blank"
               class="list-group-item">Updating national identity card information</a>
          </div>
        </div>
      </div>
    </div>

    

    <div class="row UMarginTop15">
      <div class="col-sm-12">
        <h5 :class="'title2-'+$store.state.website_color"> ডিজিটাল বাংলাদেশ দিবস ২০১৯ </h5>
        <a href="https://www.youtube.com/watch?v=tgCZJZhFHjw" target="_blank" title=""><img
                alt="ডিজিটাল বাংলাদেশ দিবস ২০১৯  	"
                src="assets/images/index_right/Digital_bangladesh_2019.jpg" style="width:100%"></a>
      </div>
    </div>
    


</template>

<script>
 import axios from 'axios';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
   data () {
      return {
        notices: {},
      }
    },
  mounted () {
      axios.get('notice').then(response => (
            this.notices = response.data
           )).catch(err => {
          console.log(err.response);
        });
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
