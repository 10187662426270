import  {createWebHashHistory, createRouter} from 'vue-router'

import Home from './components/Home.vue';

import Notice from './components/Notice.vue';
import NoticeDetails from './components/NoticeDetails.vue';
import Gallery from './components/Gallery.vue';
const routes =[
    {
        name:'Home',
        path:'/',
        component:Home,
        meta: {
            // requireSlider: true,
            rightAside: true,
        
        },


    },
    {
        name:'Gallery',
        path:'/gallery',
        component:Gallery,
        meta: {
            // requireSlider: true,
            rightAside: false,
        
        },
    },
    {
        name:'Notice',
        path:'/notice',
        component:Notice,
        meta: {
            // requireSlider: true,
            rightAside: false,
        
        },
    },
    {
        name:'Single Notice',
        path:'/notice/details/:notice_id',
        component:NoticeDetails,
        props: true,
        meta: {rightAside: false},
    },


];

const router = createRouter({
    history:createWebHashHistory(),
    routes,
});

export  default router;