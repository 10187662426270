<template>

   <div class="row UService UMarginTop20">
      <div class="col-sm-12">
        <div :class="'border_bottom-'+$store.state.website_color">
          <span :class="'title-'+$store.state.website_color"><a href="#">PHOTO GALLERY</a></span>
        </div>
        <div class="row" v-if="showDiv">
          <div class="col-sm-4" v-for="gallery in galleries" :key="gallery" @click="showImage(gallery.picture)" style="margin-bottom:15px">
            <div class="UServiceList">
              
                <!-- <h5>Development</h5> -->
                <img :src="VUE_APP_API_URL+gallery.picture" alt="Development"
                     title="Development" class="img-responsive img100" style="height:220px">
            
            </div>
          </div>
        </div>  

         <div  @click="hideImage" class="row" v-if="hideDiv" >
            <img class="card-img-top img-responsive img100" :src="VUE_APP_API_URL+singleImage"  alt="Image Cap" style="height:800px">
          </div>   
        </div>
      
    </div>



</template>

<script>
//    import LeftAside from './LeftAside.vue'
import axios from 'axios';
    export default {
        name: 'App',
        props:[


        ],
        components: {

        },
        data() {
            return {
                hideDiv : false,
                showDiv : true,
                singleImage : '',
                galleries :null,
                VUE_APP_API_URL:process.env.VUE_APP_API_URL,
                images: [
                     "https://i.wifegeek.com/200426/f9459c52.jpg",
                     "https://i.wifegeek.com/200426/5ce1e1c7.jpg",
                     "https://i.wifegeek.com/200426/5fa51df3.jpg",
                     "https://i.wifegeek.com/200426/663181fe.jpg",
                     "https://i.wifegeek.com/200426/2d110780.jpg",
                     "https://i.wifegeek.com/200426/15160d6e.jpg",
                     "https://i.wifegeek.com/200426/d0c881ae.jpg",
                     "https://i.wifegeek.com/200426/a154fc3d.jpg",
                     "https://i.wifegeek.com/200426/71d3aa60.jpg",
                     "https://i.wifegeek.com/200426/d17ce9a0.jpg",
                     "https://i.wifegeek.com/200426/7c4deca9.jpg",
                     "https://i.wifegeek.com/200426/64672676.jpg",
                     "https://i.wifegeek.com/200426/de6ab9c6.jpg",
                     "https://i.wifegeek.com/200426/d8bcb6a7.jpg",
                     "https://i.wifegeek.com/200426/4085d03b.jpg",
                     "https://i.wifegeek.com/200426/177ef44c.jpg",
                     "https://i.wifegeek.com/200426/d74d9040.jpg",
                     "https://i.wifegeek.com/200426/81e24a47.jpg",
 
               ],
            }
        },
     mounted () {
      axios.get('gallery').then(response => (
            this.galleries = response.data
           )).catch(err => {
          console.log(err.response);
        });
      },

         methods: {
            showImage(image) {
                this.showDiv = false;
                this.hideDiv = true;
                this.singleImage = image;
                window.scrollTo(400,400);

            },

            hideImage() {
                this.showDiv = true;
                this.hideDiv = false;
            },

           
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
