<template>
    <div class="row">
      <div class="col-sm-12">
        <a href="mayor-speech.php">
          <h4 :class="'title2-'+$store.state.website_color">PRICIPAL</h4>

          <div class="UImageList">
                <img :src="VUE_APP_API_URL+'management/'+principal_message.picture"
                   alt="" title="" class="img-responsive img100" style="width:250px">
                
                <h4 class="" style="margin:10px 2px;text-align: justify">{{principal_message.message}}</h4>
            
          </div>
        </a>
      </div>
    </div>

    <div class="row UMarginTop15">
      <div class="col-sm-12">
        <h5 :class="'title2-'+$store.state.website_color">TEACHERS</h5>
        <div class="UImageList">
          <div class="row UReserveCounselor">
            <div class="col-xs-4">
              <img src="assets/images/imgAll/Counselor/R1-Laila-Pervin-sm.jpg"
                   alt="Laila Pervin" title="Laila Pervin" class="img-responsive img100">
              <p>মিসেস লায়লা পারভিন</p>
            </div>
            <div class="col-xs-4">
              <img src="assets/images/imgAll/Counselor/R2-Mahfuja-sm.jpg"
                   alt="Mst. Mahfuja Begum" title="Mst. Mahfuja Begum"
                   class="img-responsive img100">
              <p>মোসাঃ মাহফুজা বেগম</p>
            </div>
            <div class="col-xs-4">
              <img src="assets/images/imgAll/Counselor/R3-Jusna-Begum-sm.jpg"
                   alt="Jusna Begum" title="Jusna Begum" class="img-responsive img100">
              <p>মিসেস জোসনা বেগম</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row UMarginTop15">
      <div class="col-sm-12">
        <h5 :class="'title2-'+$store.state.website_color">STAFFS</h5>
        <div class="UImageList">
          <div class="row UReserveCounselor">
            <div class="col-xs-4">
              <img src="assets/images/imgAll/Counselor/R1-Laila-Pervin-sm.jpg"
                   alt="Laila Pervin" title="Laila Pervin" class="img-responsive img100">
              <p>মিসেস লায়লা পারভিন</p>
            </div>
            <div class="col-xs-4">
              <img src="assets/images/imgAll/Counselor/R2-Mahfuja-sm.jpg"
                   alt="Mst. Mahfuja Begum" title="Mst. Mahfuja Begum"
                   class="img-responsive img100">
              <p>মোসাঃ মাহফুজা বেগম</p>
            </div>
            <div class="col-xs-4">
              <img src="assets/images/imgAll/Counselor/R3-Jusna-Begum-sm.jpg"
                   alt="Jusna Begum" title="Jusna Begum" class="img-responsive img100">
              <p>মিসেস জোসনা বেগম</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <div>
      <h4 :class="'title2-'+$store.state.website_color">HOLINE</h4>
      <img alt=" জরুরি হটলাইন" src="assets/images/index_right/Hotline.jpg" style="width:100%;">
    </div>
     <div class="row UMarginTop15">
      <div class="col-sm-12">
        <h4 :class="'title2-'+$store.state.website_color">ডেঙ্গু প্রতিরোধে করণীয় </h4>
        <a href="https://bangladesh.gov.bd/site/page/91530698-c795-4542-88f2-5da1870bd50c"
           target="_blank" title=""><img alt="
          ডেঙ্গু প্রতিরোধে করণীয় " src="assets/images/index_right/dengue.jpg" style="width:100%"></a>
      </div>
    </div>
 



</template>

<script>
 import axios from 'axios';
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      chairman_message: {},
      principal_message:{},
      VUE_APP_API_URL:process.env.VUE_APP_API_URL
    }
  },
mounted () {
      axios.get('messages').then(response => (
          this.chairman_message = response.data[0],
          this.principal_message = response.data[1],
          console.log(response.headers)
        )).catch(err => {
        console.log(err.response);
      });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
