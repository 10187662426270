<template>



  <header>

    <!--<div class="header-top-{{$store.state.website_color}}">-->
      <!--<div class="container">-->
        <!--<div class="row">-->
          <!--<div class="col-sm-4">-->
            <!--<div class="UHelpLine">-->
              <!--<p></p>-->
            <!--</div>-->
          <!--</div>-->
          <!--<div class="col-sm-4">-->
          <!--</div>-->
          <!--<div class="col-sm-4 USocialLink text-left">-->

            <!--<a :href="settings.facebook" style="padding-right: 5px"><i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i></a>-->
            <!--<a :href="settings.youtube"><i class="fa fa-youtube-square fa-2x" aria-hidden="true"></i></a>-->
          <!--</div>-->
        <!--</div>-->
      <!--</div>-->
    <!--</div>-->


    <div class="">
       <!--<div class="header-{{$store.state.website_color}}" style="background-image:url(assets/images/common/header-bgR.jpg);">-->
       <div :class="'header-'+$store.state.website_color" style="">
        <div class="row">
          <div class="col-sm-1">
          </div>
          <div class="col-sm-5">
            <a href="javascript:void(0)">

              <div class="">

                <img class="img-responsive" :src="VUE_APP_API_URL+settings.admin_logo "
                     align="left" alt="Logo" title="school Logo" style="float: left;
									width: 60px;height:60px">

              </div>

            </a>
          </div>

          <div class="col-sm-5" style="color:white;font-weight:bold">
            <h2 class="text-right"><i class="fas fa-university"></i> {{ settings.org_name}} </h2>
            <p class="text-right"><i class="fas fa-phone"></i> {{settings.phone}}</p>
            <p class="text-right"><i class="fas fa-location-arrow"></i> {{ settings.address}}</p>
          </div>

        </div>
      </div>
    </div>


    <div class="container">
      <!--<div class="UHeaderNav">-->
      <div :class="'header-nav-'+$store.state.website_color">
        <div class="row">
          <div class="col-sm-12">
            <nav class="navbar navbar-default">
              <div class="container-fluid">
                <div class="navbar-header">
                  <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                          data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                  </button>
                </div>

                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                  <ul class="nav navbar-nav">
                    <li><router-link class="nav-link" to="/"><i class="fa fa-home" aria-hidden="true"></i> </router-link>
                    </li>

                    <li> <router-link class="nav-link" to="/notice">Notice </router-link></li>
                    <li> <router-link class="nav-link" to="/gallery">Gallery </router-link></li>
                   <!--
                    <li> <router-link class="nav-link" to="/notice">Events </router-link></li>

                    <li> <router-link class="nav-link" to="/notice">Students </router-link></li>

                    <li> <router-link class="nav-link" to="/notice">Contact </router-link></li>
                   
                    <li> <router-link class="nav-link" to="/notice">Login </router-link></li>
                    
                    <li class="dropdown">
                      <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button"
                         aria-haspopup="true" aria-expanded="false">Drop Down<span
                              class="caret"></span></a>
                      <ul class="dropdown-menu dropdown-menus">

                        <li><a href="javascript:void(0)">তথ্য পরিষেবা কেন্দ্র</a></li>
                        <li><a href="javascript:void(0)">প্রশাসন বিভাগ</a></li>
                        <li><a href="javascript:void(0)">প্রকৌশল বিভাগ</a></li>
                        <li><a href="javascript:void(0)">স্বাস্থ্য বিভাগ</a></li>
                      </ul>
                    </li> 
                    -->

                      

                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div :class="'marquee-'+$store.state.website_color">
        <div class="row UMargin0">
          <div class="col-sm-1" style="padding: 9px 10px">NEWS</div>
          <div class="col-sm-11">
            <marquee scrollamount="4" style="padding:2px;">
              <span v-for="notice in notices" :key="notice"><i class="fa fa-play" aria-hidden="true"></i> &nbsp; {{notice.title}}</span>
              
              <!-- <i class="fa fa-play" aria-hidden="true"></i> &nbsp; আপনার পৌরসভাকে পরিচ্ছন্ন রাখুন -->
            </marquee>

          </div>
        </div>
      </div>
    </div>

  </header>


</template>

<script>

 import axios from 'axios';
    export default {
        name: 'App',
        props: {
          settings: Object,
        },
         data () {
          return {
            notices:null,
            VUE_APP_API_URL:process.env.VUE_APP_API_URL
          }
        },
        components: {

        },
      mounted () {
        axios.get('notice').then(response => (
              this.notices = response.data
            )).catch(err => {
            console.log(err.response);
            });
     }
        
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
