<template>

  <div class="row UService UMarginTop20">
    <div class="col-sm-12">
      <div :class="'border_bottom-'+$store.state.website_color" class="">
        <span :class="'title-'+$store.state.website_color"><a href="#">Notice</a></span>
      </div>
      <div class="row"  v-for="notice in notices" :key="notice" >
        <div class="col-sm-12" style="margin-bottom:15px">
          <div class="info-line UServiceList"  style="padding:7px 15px;">
            <p class="title-text" style="margin-bottom:0; "><i class="fas fa-newspaper"></i> {{ UpperCase(notice.title) }} </p>
            <p style="padding:0 0 12px 25px" class="info-line"><span class="time"><i class="far fa-clock"></i> {{ $filters.formatDateTime(notice.date).format('YYYY-MM-DD hh:mm A') }}</span><span class="place"></span></p>

            <p class="showText">{{shortText(notice.description,100)+'.....'}}</p>
            <p class="hideText">{{notice.description}}</p> <br>
            <a href="javascript:void(0)" @click="toggler($event)">Read More</a>
          </div>
        </div>
      </div>

    </div>

  </div>



</template>

<script>
//    import LeftAside from './LeftAside.vue'
import axios from 'axios';
    export default {
        name: 'App',
        props:[


        ],
        components: {

        },
        data() {
            return {

                notices: null,
            }
        },
        mounted () {
            axios.get('notice').then(response => (
                this.notices = response.data
            )).catch(err => {
                console.log(err.response);
            });
        },

        methods: {
            UpperCase(value) {
                return value.toUpperCase()
            },
            shortText(value,limit) {
                return value.substring(0,limit)
            },
            toggler(e){
                console.log(e)
                if(e.target.innerText == 'Read More'){

                    e.target.innerText = 'Show Less';
                    e.target.parentElement.children[2].className = 'hideText';
                    e.target.parentElement.children[3].className = 'showText'

                }else{
                    e.target.innerText = 'Read More';
                    e.target.parentElement.children[2].className = 'showText';
                    e.target.parentElement.children[3].className = 'hideText'
                }
            }
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .showText{
    display: inline;
  }
  .hideText{
    display: none;
  }

</style>
