<template>

  <MyHeader :settings="settings"/>


    <div class="container">

      <div class="row UMainContain">
       

        <div :class="this.$route.meta.rightAside  ? 'col-sm-6 col-lg-push-3' : 'col-sm-9 col-lg-push-3'" >
          <!--<router-view :settings='settings' :username='username'/>-->
          <router-view :key="$route.fullPath"/>
          <!--//:key="this.$route.fullPath"      To get Data  When pass id via router link-->
        </div>

       

        <!--left Aside-->
        <div :class="this.$route.meta.rightAside  ? 'col-sm-3 col-lg-pull-6': 'col-sm-3 col-lg-pull-9' ">
          <LeftAside/>
        </div>
        <!--left Aside-->
      
       <!--Right Aside-->
        <div class="col-sm-3 ">
        <RightAside v-if="this.$route.meta.rightAside"/>
         </div>
        <!--Right Aside-->


      </div>
    </div>

  <!--Footer-->
  <MyFooter/>
  <!--Footer-->
</template>

<script>
    import MyHeader from './components/Header.vue'
    import LeftAside from './components/LeftAside.vue'
    import RightAside from './components/RightAside.vue'
    import MyFooter from './components/Footer.vue'
    import axios from 'axios';
    export default {
        name: 'App',
        components: {
            MyHeader,
            LeftAside,
            RightAside,
            MyFooter,
        },
         data () {
            return {
              settings: {},
              VUE_APP_API_URL:process.env.VUE_APP_API_URL
            }
          },

        computed:{

        },
        mounted () {
           console.log(process.env.VUE_APP_API_URL);
//            console.log(this.$store.dispatch("websiteColor"));
            this.$store.dispatch("websiteColor");
            axios.get('settings').then(response => (
                 this.settings = response.data
               
               
               )).catch(err => {
                console.log(err.response);
              });
         },


    }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
