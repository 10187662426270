<template>


  <section>
    <div class="">
      <div class="row UFTop">
        <div class="col-sm-12"><img src="assets/Footer_Image.png" title="" alt=""
                                    class="img-responsive img100"></div>
      </div>
    </div>
  </section>
  <footer id="footer" style="padding:80px  50px 0 50px">


    <div class="container-fluid">
      <section>
        <div class="row">
          <div class="col-md-4 contact">
            <h3>Contact</h3>
            <ul>
               <li class="address"><a href="#">{{ settings.address}}</a></li>
              <li class="mail"><a href="#">{{ settings.email}}</a></li>
              <li class="phone last"><a href="#">{{ settings.phone}}</a></li>
            </ul>
          </div>

        
          <div class="col-md-4 social-media">
            <h3>Social media</h3>
            <ul>
              <div class="row ">
                <div class="col-md-6 ">
                  <li class=""><a href="">Facebook</a></li>
                </div>
                <div class="col-md-6">
                  <li class="google-plus"><a href="">Google+</a></li>
                </div>
                <div class="col-md-6 ">
                  <li class="twitter"><a href="">Twitter</a></li>
                </div>
                <div class="col-md-6">
                  <li class="pinterest"><a href="">Pinterest</a></li>
                </div>
              </div>

            </ul>
          </div>
          <div class="col-md-4">
            <h3>Newsletter</h3>
            <form action="#">
              <input placeholder="Email address..." type="text">
              <button type="submit">Subscribe</button>
            </form>
            <ul>
              <li><a href="#"></a></li>
            </ul>
          </div>
        </div>
      </section>

    </div>

  </footer>
  <section>
    <div class="">
      <div class="row UFBottom UFooterBG">

        <div class="col-sm-12">
          <p>Copyright &copy; 2023 | All right &reg; Reserved By <a href="javascript:void(0)"></a>. Developed by <a href="javascript:void(0)" target="_blank">Bugfix Hub</a></p>
        </div>
      </div>
    </div>
  </section>


</template>

<script>
    import axios from 'axios';
    export default {
       name: 'HelloWorld',
        props: {
          msg: String
        },
         data () {
          return {
            settings:{},
            VUE_APP_API_URL:process.env.VUE_APP_API_URL
          }
        },
        components: {

        },
      mounted () {
        axios.get('settings').then(response => (
            this.settings = response.data
          )).catch(err => {
          console.log(err.response);
          });
     }
        
    }

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
